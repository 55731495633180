<template>
  <div class="page-list personnelfiles flex-1">
    <a-row class="filter-wrap">
      <a-col
        :lg="8"
        :md="24"
        :sm="24"
        :xs="24"
        class="mgb-20"
        style="margin-left:0px;"
      >
        <a-button
          type="primary"
          class="table-btn-clk"
          style="margin-right:19px;margin-left: 0px;"
          @click="addRules"
        >添加规则</a-button>
      </a-col>
      <a-col
        :lg="16"
        :md="24"
        :sm="24"
        :xs="24"
        class="text-right flex align-item-center j-end "
      >
        <a-select
          allow-clear
          class="mgl-20 pgb-20 width-min-164"
          v-model="query.system_id"
          placeholder="选择系统"
        >
          <a-select-option
            v-for="it in sysList"
            :key="it.system_id"
            :value="it.system_id"
          >
            {{it.system_name}}
          </a-select-option>
        </a-select>
        <!-- <a-input
          @search="getList"
          @pressEnter="getList"
          v-model="query.btnTxt"
          placeholder="按钮名称"
          class="mgl-20 mgl-20 ant-input2"
          style="width: 168px;"
        >
          <template slot="prefix">
            <i
              style="margin-left: -2px;"
              slot="prefix"
              class=" meiye-icon meiye-sousuo"
            ></i>
          </template>
        </a-input> -->
        <a-select
          allow-clear
          class="mgl-20 pgb-20 width-min-164"
          v-model="query.rules_status"
          placeholder="规则状态"
        >
          <!-- :filter-option="$Common.filterOption" -->
          <a-select-option
            v-for="it in ruleList"
            :key="it.id"
            :value="it.id"
          >
            {{it.name}}
          </a-select-option>
        </a-select>
        <a-button
          class="mgl-20 table-btn-clk"
          type="primary"
          style="height:32px;margin-top:1px;"
          @click="getList()"
        >查询</a-button>
      </a-col>
    </a-row>
    <a-table
      class="table-manage flex-1"
      @change="tableChanged"
      :pagination="false"
      row-key="permission_id"
      :loading="table.loading"
      :columns="config.permission.columns"
      :data-source="table.tableData"
      :expanded-row-keys.sync="expandedRowKeys"
      :indentSize="15"
      :expandRowByClick="false"
      :expandIconAsCell="false"
      :expandIconColumnIndex="1"
      childrenColumnName="children"
    >
      <!-- :expandIcon="(props)=>this.customExpandIcon(props)" -->
      <template
        slot="_6"
        slot-scope="status, record"
      >
        {{ record.permission_component || '--' }}
      </template>
      <template
        slot="_3"
        slot-scope="status, record"
      >
        <div
          class="img"
          style="width:100%;"
        >
          <i
            class="meiye-icon"
            :class="record.permission_icon"
            style="width:40px;height: 40px; font-size:32px;margin:0 auto;display: inline-block; "
          ></i>
          <!-- <a-icon
            style="width:40px;height: 40px; font-size:32px;margin:0 auto;display: inline-block; "
            :type="record.permission_icon"
          /> -->
        </div>

      </template>
      <template
        slot="_10"
        slot-scope="status, record"
      >
        <a-switch
          :default-checked="record.permission_is_show?true:false"
          @change="changeStatus($event,record)"
        ></a-switch>
      </template>
      <template
        slot="_7"
        slot-scope="action, record"
      >
        <a-button
          size="small"
          class="mgr-12 smallbtn"
          @click="addRules(record,2)"
        >添加子菜单</a-button>
        <a-button
          size="small"
          class="mgr-12 smallbtn"
          @click="addRules(record,3)"
        >编辑</a-button>
        <a-button
          size="small"
          class="mgr-12 smallbtn"
          @click="handleBatch('delete',[record],'提示')"
        >删除</a-button>
      </template>
    </a-table>
    <a-modal
      :visible="permissionInfo.visible"
      width="750px"
      wrapClassName="commonModel"
      @cancel="permissionInfo.visible=false"
      :okText="permissionInfo.text"
      @ok="okclickmod"
      destroyOnClose
      :bodyStyle="{height: '328px'}"
    >
      <div
        class="constmodel flex"
        style="justify-content: space-between;"
      >
        <div class="leftbox">
          <div class="linebox flex align-item-center">
            <div class="lfbox">类型</div>
            <a-radio-group
              v-model="permissionInfo.info.permission_type"
              @change="onChangePermissiontype"
            >
              <a-radio :value="1">
                菜单
              </a-radio>
              <a-radio :value="2">
                权限
                <!-- (菜单只显示三级) -->
              </a-radio>
            </a-radio-group>
            <!-- <div class="rgbox">{{ permissionInfo.info.permission_type }}</div> -->
          </div>
          <div class="linebox flex align-item-center">
            <div class="lfbox">权限名称</div>
            <div class="rgbox">
              <a-input
                v-model="permissionInfo.info.permission_name"
                placeholder="请输入权限名称"
              ></a-input>
            </div>

          </div>
          <div class="linebox flex align-item-center">
            <div class="lfbox">上级权限</div>
            <div class="rgbox">
              <a-tree-select
                :disabled="permissionInfo.isDisabled"
                v-model="permissionInfo.info.permission_parent"
                style="width: 196px;"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="treeData"
                placeholder="请选择上级权限"
                :replaceFields="selectreeObj"
              >
              </a-tree-select>
            </div>
          </div>
          <div class="linebox flex align-item-center">
            <div class="lfbox">权限对应组建：</div>
            <div class="rgbox">
              <a-input
                v-model="permissionInfo.info.permission_component"
                placeholder="请输入权限对应组建"
              ></a-input>
            </div>
          </div>
          <div class="linebox flex ">
            <div class="lfbox">权限icon</div>
            <div class="rgbox">
              <!-- <a-upload
                name="avatar"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :action="baseUrl+'/upload'"
                :headers="headers"
                :before-upload="beforeUpload"
                :customRequest="uploadFiles"
                @change="handleChange"
                style=""
              >
                <img
                  v-if="permissionInfo.info.permission_icon"
                  :src="permissionInfo.info.permission_icon"
                  alt="avatar"
                  @error="$utils.imageOnerror($event)"
                />
                <div v-else>
                  <a-icon :type="permissionInfo.pic_url_loading ? 'loading' : 'plus'" />
                </div>
              </a-upload> -->
              <icon-picker
                v-model="permissionInfo.info.permission_icon"
                :icon="permissionInfo.info.permission_icon"
                :trigger="'hover'"
                @getIconName="getIconName"
              >
                <template #iconSelect>
                  <div class="icons">
                    <i
                      class="meiye-icon"
                      v-if="permissionInfo.info.permission_icon"
                      :class="permissionInfo.info.permission_icon"
                    ></i>
                    <a-icon
                      type="plus"
                      v-else
                    />
                  </div>

                </template>
              </icon-picker>
            </div>
          </div>
        </div>
        <div class="rightbox">
          <div
            class="linebox flex align-item-center"
            v-if="permissionInfo.info.permission_type==1"
          >
            <div class="lfbox">是否展示枚举值</div>
            <a-radio-group v-model="permissionInfo.info.permission_is_show">
              <a-radio :value="1">
                展示
              </a-radio>
              <a-radio :value="0">
                影藏
              </a-radio>
            </a-radio-group>
            <!-- <div class="rgbox">{{ permissionInfo.info.permission_type }}</div> -->
          </div>
          <div class="linebox flex align-item-center">
            <div class="lfbox">权限路由</div>
            <div class="rgbox">
              <a-input
                v-model="permissionInfo.info.permission_route"
                placeholder="请输入权限路由"
              ></a-input>
            </div>
          </div>
          <div class="linebox flex align-item-center">
            <div class="lfbox">权限参数</div>
            <div class="rgbox">
              <a-input
                v-model="permissionInfo.info.permission_params"
                placeholder="请输入权限参数"
              ></a-input>
            </div>

          </div>
          <div class="linebox flex align-item-center">
            <div class="lfbox">排序</div>
            <div class="rgbox">
              <a-radio-group v-model="permissionInfo.info.permission_sort">
                <a-radio :value="1">
                  正序
                </a-radio>
                <a-radio :value="0">
                  倒序
                </a-radio>
              </a-radio-group>
            </div>
          </div>

        </div>
      </div>
      <template slot="title">
        <div style="margin: 3px 0;">
          <span class="titmodel">{{ permissionInfo.title }}</span>
        </div>
      </template>
    </a-modal>
  </div>

</template>
<script>
import storage from 'store'
import { Perlist, SystemList, PermissionInfo, CreatePermission, UpdatePermission, GetparentOptions, DeletePermission } from '@/api/permission'
import config from './config'
import axios from 'axios'
import iconPicker from '@/components/intimate/IconPicker'

export default {
  components: { iconPicker },
  data() {
    let self = this
    return {
      config,
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      activeComp: null,
      batchVisible: false,
      sysList: [], //系统列表
      ruleList: [
        { id: 1, name: '展示' },
        { id: 0, name: '隐藏' },
      ],// 规则状态 
      query: {
        btnTxt: undefined,
        rules_status: undefined,
        system_id: undefined,
        per_page: 10,
        page: 1,

      },
      table: {
        tableData: [],
        selectedRowKeys: [],
        loading: false,
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      },
      expandedRowKeys: [],//树形
      parentId: undefined,
      permissionInfo: {
        visible: false,
        text: '提交',
        title: '',
        type: 1,//1新增 2新增子 3编辑
        pic_url_loading: false,
        isDisabled: false,
        placeholder: '请选择权限',
        info: {
          permission_type: undefined,
          permission_name: '',
          permission_parent: undefined,
          permission_icon: '',
          permission_route: '',
          permission_params: undefined,
          permission_is_show: undefined,//0 1
          permission_sort: '0',
          permission_id: undefined,
          system_id: undefined,
          permission_component: undefined,
        }

      },
      treeData: [
        // {
        //   permission_id: "43",
        //   permission_name: "w",
        //   permission_parent: 0
        // },
        // {
        //   permission_id: "42",
        //   permission_name: "we",
        //   permission_parent: 0,
        //   children: [
        //     {
        //       permission_id: "45",
        //       permission_name: "wee",
        //       permission_parent: 42,
        //       children: [
        //         {
        //           permission_id: "46",
        //           permission_name: "data",
        //           permission_parent: 45
        //         }
        //       ]
        //     }
        //   ]
        // },
        // {
        //   permission_id: "41",
        //   permission_name: "ss",
        //   permission_parent: 0
        // }
      ],
      headers: {
        token: storage.get('token'),
        "Content-Type": 'multipart/form-data'
      },
      selectreeObj: {
        children: 'children',
        title: 'permission_name',
        key: 'permission_id',
        value: 'permission_id'
      },
      permission_id: "41",
    }
  },
  async created() {
    let self = this
    this.getSystemList()

  },
  methods: {
    handleMenuClick({ key }) {
      this.handleBatch(key)
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.info.pic_url_loading = true
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        const { file_url } = info.file.response.data
        this.permissionInfo.info.permission_icon = file_url
        this.permissionInfo.info.pic_url_loading = false
      }
    },
    // 删除 权限
    handleBatch(key, record, title = "删除") {
      let self = this
      const urlArr = [
        { name: DeletePermission, key: 'delete', okType: 'primary', msg: '确定要删除吗？删除后将无法恢复，请谨慎操作！' },
      ]
      const activeObj = urlArr.filter(it => it.key == key)[0]
   //
      self.$confirm({
        icon: 'exclamation-circle',
        closable: true,
        title,
        content: activeObj.msg,
        okText: '确定',
        closable: true,
        okType: activeObj.okType,
        cancelText: '取消',
        onOk() {
          const data = {
            permission_id: record[0].permission_id,
          };
          (activeObj.name)(data).then(res => {
            self.getList()
          }).finally(r => {
          })
        },
        onCancel() {
        },
      });
    },
    
    getList() {
      let self = this
      self.table.loading = true
      const params = {
        filter: {
          permission_is_show: self.query.rules_status,
          permission_name: self.query.btnTxt,
          system_id: self.query.system_id,
        },
        page: self.query.page,
        per_page: self.query.per_page,
        order: {
          permission_sort: "asc"
        }
      }

      return Perlist(params).then(res => {
        console.log(res.data, "data");
        const { data = [], total = 0 } = res
        self.table.tableData = data
        // self.table.pagination = Object.assign({}, self.table.pagination, {
        //   total: total
        // })
      }).finally(r => {
        self.table.loading = false
      })
    },
    // 系统列表
    getSystemList() {
      SystemList().then(res => {
        this.sysList = res.data
        this.query.system_id = res.data[0].system_id
        this.getList()
      })
    },

    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination
      const { field, order } = sorter
      // this.query = Object.assign(this.query, { per_page: Number(pageSize), page: Number(current) })
      // this.getList()
    },

    changeStatus($event, e) {
      let obj = {}
      this.table.tableData.forEach(el => {
        if (e.permission_id == el.permission_id) {
          el.permission_is_show = $event ? 1 : 0
          obj = el
        }
      })
      this.permissionInfo.type = 3
      this.permissionInfo.info = {
        permission_type: obj.permission_type,
        permission_name: obj.permission_name,
        permission_parent: obj.permission_parent.toString(),
        permission_icon: obj.permission_icon,
        permission_route: obj.permission_route,
        permission_params: obj.permission_params,
        permission_is_show: obj.permission_is_show,
        permission_sort: obj.permission_sort,
        permission_id: obj.permission_id,
        system_id: obj.system_id,
        permission_component: obj.permission_component
      }
      this.okclickmod()
    },
    onChangePermissiontype(e) {
      console.log('radio checked', e.target.value, this.permissionInfo.info.permission_type);
    },
    // 提交按钮
    okclickmod(e) {
      let data = {
        ...this.permissionInfo.info
      }
      if (this.permissionInfo.type == 3) {
        UpdatePermission(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        })
      } else {
        CreatePermission(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    async addRules(e, i) {
      this.permissionInfo.isDisabled = false
      this.permissionInfo.info = {
        permission_type: 1,
        permission_name: undefined,
        permission_parent: undefined,
        permission_icon: undefined,
        permission_route: undefined,
        permission_params: undefined,
        permission_is_show: undefined,//0 1
        permission_sort: '0',
        permission_id: undefined,
        system_id: this.query.system_id,
        permission_component: undefined,
      }
      await this.getparentOptions()

      let timer = setTimeout(() => {
        console.log(this.treeData);
        this.permissionInfo.visible = true
        this.permissionInfo.title = '添加权限'
        this.permissionInfo.type = 1
        if (i == 2) {
          this.permissionInfo.title = '添加子权限'
          this.permissionInfo.type = 2
          this.permissionInfo.info.permission_id = e.permission_id
          this.permissionInfo.info.system_id = e.system_id

          this.openDatails()
          console.log(this.permissionInfo);
        }
        if (i == 3) {
          this.permissionInfo.isDisabled = true
          this.permissionInfo.title = '编辑权限'
          this.permissionInfo.type = 3
          this.permissionInfo.info.permission_id = e.permission_id
          this.permissionInfo.info.system_id = e.system_id
          this.openDatails()
          console.log(this.permissionInfo.info.permission_parent);
        }

        clearTimeout(timer)
        this.timer = ''
      }, 800)


    },

    openDatails() {
      PermissionInfo({ permission_id: this.permissionInfo.info.permission_id }).then(res => {
        this.permissionInfo.info = Object.assign(this.permissionInfo.info, res.data)
        this.permissionInfo.info.permission_parent = res.data.permission_id.toString()
        if (this.permissionInfo.type == 2) {
          this.permissionInfo.isDisabled = true
          this.permissionInfo.info.permission_name = undefined
          this.permissionInfo.info.permission_icon = undefined
          this.permissionInfo.info.permission_route = undefined
          this.permissionInfo.info.permission_is_show = undefined
          this.permissionInfo.info.permission_type = undefined
          this.permissionInfo.info.permission_params = undefined
        }
      })
    },
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    getparentOptions() {
      let date = {
        filter: {
          system_id: this.query.system_id
        }
      }
      GetparentOptions(date).then(res => {
        this.treeRecursion(res.data)
        this.treeData = res.data
      })
    },
    // 递归改变id key number
    treeRecursion(data) {
      data.forEach(el => {
        // 把数字类型转为字符串 
        if (typeof (el.permission_id) == 'number') {
          el.permission_id = el.permission_id.toString()
        }
        if (el.children && el.children.length) this.treeRecursion(el.children);
      })
    },
    // 自定义展开关闭图标
    customExpandIcon(props) {
      console.log(props)
      // if(props.record.children.length > 0){
      if (props.expanded) {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">关闭详情</a-button></a>
      } else {
        return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
          props.onExpand(props.record, e);
        }}><a-button type="link">查看详情</a-button></a>
      }
      // }else{
      //     return <span style={{marginRight:8 }}></span>
      // }
    },

    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.permissionInfo.info.permission_icon = res.data.url
        console.log(this.permissionInfo.info.permission_icon);
      } else {
        this.$message.error(res.error.message)
      }


    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    // 获取icon
    getIconName(e) {
      this.permissionInfo.info.permission_icon = e
    }

  },

}
</script>
<style lang="less" scoped>
.personnelfiles {
  // padding: 12px;
  margin: 20px 12px 20px 20px;
  overflow: hidden;
  // background: #fff;
  // height: 100%;
  // min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  .filter-wrap {
    background: #fff;
    border-radius: 4px 4px 0 0 ;
  }
  /deep/ .ant-checkbox-wrapper {
    background: transparent;
    border: none;
    box-shadow: none;
  }
}
.borderbox {
  margin-left: 4px;
  display: inline-block;
  border-radius: 6px;
  text-align: center;
  width: 20px;
  height: 18px;
  background: @btn2mianbgDColor;
  box-shadow: 0px 2px 3px 0px @btn2mianOutDColor,
    inset 0px -2px 0px 0px @btn2mianInsertDColor;
  border-radius: 4px;
  border: 1px solid @btn2mianboderDColor;
  line-height: 14px;

  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.bigdropdown_imga {
  display: none;
}
.bigdropdown_img1a {
  display: none;
}
.bigdropdown {
  position: relative;
  width: 100px;
  height: 18px;
  display: inline-block;
  line-height: 18px;
  .borderbox {
    position: absolute;
    top: 0;
    left: 29px;
    i {
      font-size: 8px;
      margin-top: -1px;
      transform: scale(0.83);
      display: inline-block;
      color: @btn2mianfontDColor;
    }
    &:hover {
      border: 1px solid @btn2mianboderHColor;
      i {
        color: @btn2mianfontHColor;
      }
    }
  }
}
/deep/ .bgchangenode {
  background: #ccc;
}
/deep/.ant-modal-header {
  padding-left: 24px;
}
/deep/.ant-modal-footer {
  padding-bottom: 32px;
}
.btn-padding {
  display: none;
  // background: rgba(216, 219, 236, 0.27);
  border-radius: 4px;
  color: @btn3mianFontDColor;
  background: #f5f5fa;
  border: none !important;
  box-shadow: none !important;
  &:hover {
    color: @btn3mianFontHColor !important;
    background-color: @btn3mianbgHColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  &:focus {
    color: @btn3mianFontFColor !important;
    background-color: @btn3mianbgFColor !important;
    border: none !important;
    box-shadow: none !important;
  }
  // border: 1px solid @btn2mianboderDColor;
  // box-shadow: 0px 2px 4px 0px @btn2mianOutDColor, inset 0px -2px 0px 0px @btn2mianInsertDColor;
  // background-color: @btn2mianbgDColor;
  // color: @btn2mianfontDColor;
  // &:active {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor !important;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:focus {
  //   color: @btn2mianfontFColor !important;
  //   border: 1px solid @btn2mianboderFColor !important;
  //   background-color: @btn2mianbgFColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutFColor, inset 0px -2px 0px 0px @btn2mianInsertFColor !important;
  // }

  // &:hover {
  //   color: @btn2mianfontHColor !important;
  //   border: 1px solid @btn2mianboderHColor !important;
  //   background-color: @btn2mianbgHColor;
  //   box-shadow: 0px 2px 4px 0px @btn2mianOutHColor, inset 0px -2px 0px 0px @btn2mianInsertHColor;
  // }
}
/deep/ .ant-table-header {
  margin-top: -23px;
}
/deep/ .borderbox.ant-dropdown-open {
  border-color: @baseColor35 !important;
  color: @baseColor35 !important;
  i {
    color: @baseColor35 !important;
  }
}
/deep/
  .ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ),
.ant-cascader-menu-item:hover,
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover,
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-tree li .ant-tree-node-content-wrapper:hover,
.ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
  background-color: @selectdownbgHColor !important;
  // color: #0A955D;
  color: @selectdownfontHColor;
  font-weight: 400;
}
.smallbtnlong {
  letter-spacing: -1px !important;
}
/deep/ .ant-table-body {
  overflow: hidden !important;
  overflow-y: scroll !important;
}
.meiye-sousuo {
  color: @primaryColor;
}
/deep/ .ant-input2 .ant-input {
  padding-top: 5px;
  &::placeholder {
    padding-top: 3px;
  }
}
.linebox {
  display: flex;
  padding: 12px 0;

  .lfbox {
    width: 110px;
    font-size: 14px;
    color: @fontColor5;
    text-align: left;
    .imgxing {
      width: 8px;
      height: 22px;
    }
  }

  .rgbox {
    font-size: 14px;
    color: @btnColorfont1;
    // width: calc(100% - 120px);
    position: relative;

    .tuijia {
      font-size: 14px;
      color: @fontColor6;
    }
  }
}
/deep/ .ant-upload-picture-card-wrapper .ant-upload {
  width: 32px !important;
  height: 32px !important;
  padding: 0;
}
/deep/ .ant-upload-select-picture-card i {
  font-size: 14px;
}
.icons {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  border: 1px dashed #cbcbcb;
  .icon {
    font-size: 18px;
  }
  cursor: pointer;
  &:hover {
    color: @primaryColor;
    border: 1px dashed @primaryColor;
  }
}
</style>